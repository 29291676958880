import { ExclamationIcon } from '@heroicons/react/outline'
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/solid'
import { useCallback, useMemo } from 'react'

import { classNames } from '../utils'

export const BannerTypes = ['success', 'info', 'warning', 'error'] as const

export type BannerType = (typeof BannerTypes)[number]

export interface BannerProps {
  title?: string
  message?: string
  CustomIcon?: JSX.Element
  containerClassName?: string
  type: BannerType
  onClose?: () => void
  CTA?: JSX.Element
  hideIcon?: boolean
}

export const Banner: React.FC<BannerProps> = ({
  title,
  message,
  CustomIcon,
  containerClassName,
  type,
  onClose,
  CTA,
  hideIcon,
}: BannerProps) => {
  const bannerStyles = useMemo(() => {
    const data: {
      container: string
      title: string
      message: string
      hideButton: string
    } = {
      container: '',
      title: '',
      message: '',
      hideButton: '',
    }

    switch (type) {
      case 'success':
        data.container = 'bg-emerald-50 border border-emerald-300'
        data.title = 'text-emerald-800'
        data.message = 'text-emerald-700'
        data.hideButton = 'text-slate-500 '
        break
      case 'error':
        data.container = 'bg-red-50 border border-red-300'
        data.title = 'text-red-800'
        data.message = 'text-red-700'
        data.hideButton = 'text-slate-500 '
        break
      case 'info':
        data.container = 'bg-slate-50 border border-slate-200'
        data.title = 'text-slate-900'
        data.message = 'text-slate-700'
        data.hideButton = 'text-slate-500 '
        break
      case 'warning':
        data.container = 'bg-yellow-50 border border-yellow-300'
        data.title = 'text-yellow-800'
        data.message = 'text-yellow-700'
        data.hideButton = 'text-slate-500 '
        break
    }

    return data
  }, [type])

  const BannerIcon: React.FC = useCallback(() => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon className="w-5 h-5 text-emerald-500" />
      case 'info':
        return <InformationCircleIcon className="w-5 h-5 text-slate-400" />
      case 'warning':
        return <ExclamationIcon className="w-5 h-5 text-yellow-500" />
      case 'error':
        return <ExclamationIcon className="w-5 h-5 text-red-500" />
    }
  }, [type])

  return (
    <div
      className={classNames(
        'flex flex-row rounded items-center py-2 px-2.5',
        bannerStyles.container || '',
        containerClassName || '',
      )}
    >
      {!hideIcon ? (
        <div className="self-center">
          {CustomIcon ? CustomIcon : <BannerIcon />}
        </div>
      ) : null}

      <div className={classNames('flex flex-col items-start ml-2.5')}>
        <p
          className={classNames(
            title && message ? 'font-semibold' : 'font-medium leading-4',
            bannerStyles.title || '',
          )}
        >
          {title}
        </p>

        {message ? (
          <p className={classNames(bannerStyles.message || '')}>{message}</p>
        ) : null}
      </div>

      <div className="flex flex-row gap-5 ml-auto">
        {CTA ? CTA : null}

        {onClose ? (
          <XIcon
            className={classNames(
              'h-4 w-4 mt-0.5 ml-auto cursor-pointer max-w-4 self-center',
              bannerStyles.hideButton || '',
            )}
            onClick={() => {
              onClose()
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Banner
