import type { FC, ReactNode } from 'react'

export interface TableProps {
  className?: string
  head?: ReactNode
  body: ReactNode
  foot?: ReactNode
}

export const Table: FC<TableProps> = ({
  className,
  head,
  body,
  foot,
  ...rest
}) => {
  return (
    <table className="table min-w-full">
      {head && <thead className="bg-white">{head}</thead>}
      <tbody className="bg-white">{body}</tbody>
      {foot && <tfoot className="bg-white">{foot}</tfoot>}
    </table>
  )
}

export default Table
