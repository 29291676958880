import { toDisplayJobStatus } from '@/utils/toDisplayJobStatus'
import { Badge } from '@people/ui/badges'
import { AtsJobStatus } from '@prisma/client'
import { useTranslation } from 'next-i18next'
import type { FC } from 'react'

export type BadgeSize = 'md' | 'xl'

export interface JobStatusBadgeProps {
  status: AtsJobStatus
  size: BadgeSize
}

export const JobStatusBadge: FC<JobStatusBadgeProps> = ({ status, size }) => {
  const { t } = useTranslation('common')

  return (
    <Badge
      className="capitalize"
      size={size}
      variant={
        status === 'LIVE'
          ? 'success'
          : status === 'CLOSED'
          ? 'warning'
          : status === 'ARCHIVED'
          ? 'error'
          : 'info'
      }
    >
      <span className="font-medium">{toDisplayJobStatus(status, t)}</span>
    </Badge>
  )
}
