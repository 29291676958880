import { FC } from 'react'

import IconProps from './IconProps'

export const EmbedIcon: FC<IconProps> = ({ className, ...other }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80476 4.47145C6.06511 4.2111 6.06511 3.78899 5.80476 3.52864C5.54441 3.26829 5.1223 3.26829 4.86195 3.52864L0.861949 7.52864C0.6016 7.78899 0.6016 8.2111 0.861949 8.47145L4.86195 12.4714C5.1223 12.7318 5.54441 12.7318 5.80476 12.4714C6.06511 12.2111 6.06511 11.789 5.80476 11.5286L2.27616 8.00004L5.80476 4.47145ZM11.1381 3.52864C10.8777 3.26829 10.4556 3.26829 10.1953 3.52864C9.93493 3.78899 9.93493 4.2111 10.1953 4.47145L13.7239 8.00004L10.1953 11.5286C9.93493 11.789 9.93493 12.2111 10.1953 12.4714C10.4556 12.7318 10.8777 12.7318 11.1381 12.4714L15.1381 8.47145C15.3984 8.2111 15.3984 7.78899 15.1381 7.52864L11.1381 3.52864Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EmbedIcon
