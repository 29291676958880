import { flexRender, HeaderGroup } from '@tanstack/react-table'
import type { FC } from 'react'

import { classNames } from '../utils/classNames'
import { TableSorter } from './'

export interface TableHeaderProps {
  className?: string
  header: HeaderGroup<any>
  selected?: boolean
}

export const TableHeader: FC<TableHeaderProps> = ({
  className,
  header,
  selected,
}) => {
  return (
    <tr
      key={header.id}
      className={classNames(
        className ?? '',
        'border-t border-slate-200',
        selected ? 'bg-slate-100' : '',
      )}
    >
      {header.headers.map(header => (
        <th
          key={header.id}
          className={classNames(
            'whitespace-nowrap px-2 py-3 h-12 text-left text-sm font-normal text-slate-600 first:pl-[18px] last:pr-[18px]',
          )}
          style={{
            width: header.column.getSize(),
          }}
        >
          {header.isPlaceholder ? null : (
            <div
              {...{
                className: header.column.getCanSort()
                  ? 'cursor-pointer select-none flex-row flex justify-start items-center'
                  : 'flex-row',
                onClick: header.column.getToggleSortingHandler(),
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() && (
                <TableSorter isSorted={header.column.getIsSorted()} />
              )}
            </div>
          )}
        </th>
      ))}
    </tr>
  )
}

export default TableHeader
