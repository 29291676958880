import type { AtsJobStatus } from '@prisma/client'

export const toDisplayJobStatus = (
  jobStatus: AtsJobStatus,
  t: (key: string) => string,
): string => {
  switch (jobStatus) {
    case 'DRAFT':
      return t('draft')
    case 'LIVE':
      return t('active')
    case 'CLOSED':
      return t('inactive')
    case 'ARCHIVED':
      return t('deleted')
    case 'INVISIBLE':
      return t('invisible')
    default:
      return t('draft')
  }
}
