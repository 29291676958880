import { trpc } from '@/utils/trpc'
import { CheckIcon } from '@heroicons/react/outline'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

import { Button } from '@people/ui/buttons'
import { useNotification } from '@people/ui/hooks/useNotification'
import { HeaderModal } from '@people/ui/modals'
import { Banner } from '@people/ui/notification'
import { ButtonSpinner } from '@people/ui/spinners'
import { classNames } from '@people/ui/utils'

interface DuplicateJobModalProps {
  jobId: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
}

export const DuplicateJobModal: React.FC<DuplicateJobModalProps> = ({
  isOpen,
  setIsOpen,
  jobId,
}) => {
  const { t } = useTranslation('common')
  const [duplicatingToLocation, setDuplicatingToLocation] = useState<{
    id: string
    name: string
    lousyLocationSlug: string
  }>()
  const { showNotification } = useNotification()
  const { query } = useRouter()
  const orgSlug = query.orgSlug as string

  const { data, isLoading } = trpc.useQuery([
    'organisationLocation.getLocationsWithPositionJob',
    { jobId: jobId },
  ])

  const utils = trpc.useContext()

  const { mutate: duplicateJob, isLoading: isDuplicatingJob } =
    trpc.useMutation('ats.job.duplicateJob', {
      onSuccess: duplicatedJob => {
        utils.invalidateQueries(['ats.job.getByLocation'])
        showNotification({
          title: `${t('jobDuplicated')}!`,
          message: () => {
            return (
              <div className="mt-1 text-sm text-slate-600">
                <Link
                  className="text-blue-500"
                  href={`/${orgSlug}/${data?.currentJob.organisationLocation.lousyLocationSlug}/${data?.currentJob.lousySlug}`}
                >
                  <a className="text-blue-500"> {data?.currentJob.title}</a>
                </Link>{' '}
                {t('wasDuplicatedTo')}{' '}
                <Link
                  href={`/${orgSlug}/${duplicatedJob.lousyLocationSlug}`}
                  className="text-blue-500"
                >
                  <a className="text-blue-500">{duplicatedJob.locationName}</a>
                </Link>
              </div>
            )
          },
          type: 'success',
          timeout: 5000,
        })
        setIsOpen(false)
      },
      onError: err => {
        showNotification({
          title: t('error'),
          message: err.message,
          type: 'error',
        })
      },
    })

  const DuplicateJobHeaderContent = useCallback((): JSX.Element => {
    return (
      <div className="flex flex-col gap-1 items-start">
        <h3 className="font-semibold text-slate-900">
          {t('duplicateJob')}: {data?.currentJob.title}
        </h3>
        {data && (
          <div className="flex flex-col items-start gap-1">
            <p className="text-slate-500">
              {t('currentlyIn')}:{' '}
              <a
                className="text-blue-600"
                href={`/${orgSlug}/${data.currentJob.organisationLocation.lousyLocationSlug}`}
                target="_blank"
              >
                {data.currentJob.organisationLocation.name}
              </a>
            </p>
            {duplicatingToLocation && (
              <p className="text-slate-500">
                {t('duplicateTo')}:{' '}
                <a
                  href={`/${orgSlug}/${duplicatingToLocation.lousyLocationSlug}`}
                  className="text-blue-600"
                  target="_blank"
                >
                  {duplicatingToLocation.name}
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    )
  }, [data, duplicatingToLocation])

  return (
    <HeaderModal
      header={DuplicateJobHeaderContent}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="p-1">
        {data && (
          <div className="flex flex-col gap-2.5 items-center py-2">
            {isLoading
              ? Array(6)
                  .fill(null)
                  .map((_, locIdx) => {
                    return (
                      <div
                        key={locIdx}
                        className="w-[95%] border  rounded flex flex-col items-start !text-slate-500 bg-slate-200 animate-pulse py-2 px-2.5 h-10"
                      ></div>
                    )
                  })
              : data.locations?.map((location, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        'w-[95%] border border-slate-200 rounded flex flex-col items-start !text-slate-500',
                        duplicatingToLocation?.id === location.id
                          ? 'border-blue-500 text-blue-500'
                          : 'border-slate-200 text-slate-900 cursor-pointer',
                      )}
                      onClick={() => {
                        if (isDuplicatingJob) {
                          return
                        }

                        setDuplicatingToLocation({
                          id: location.id,
                          name: location.name,
                          lousyLocationSlug: location.lousyLocationSlug,
                        })
                      }}
                    >
                      <div className="w-[100%] flex">
                        <p className="py-2 px-2.5 font-medium">
                          {location.name}
                        </p>

                        {duplicatingToLocation?.id === location.id ? (
                          <CheckIcon className="ml-auto w-5 text-blue-500 mr-1" />
                        ) : null}
                      </div>
                    </div>
                  )
                })}
          </div>
        )}
        {data &&
        duplicatingToLocation?.id ===
          data.currentJob.organisationLocation.id ? (
          <Banner
            containerClassName="mx-2.5 mb-2"
            type="warning"
            title={t('duplicationToSameLocationNote')}
          />
        ) : null}
      </div>

      <div className="border-t flex flex-row justify-end py-[14px] px-5 gap-3">
        <Button
          variant="secondary"
          onClick={() => setIsOpen(false)}
          type="reset"
        >
          {t('cancel')}
        </Button>

        <Button
          disabled={!duplicatingToLocation || isDuplicatingJob}
          variant="primary"
          className="disabled:text-slate-400 disabled:bg-slate-100"
          type="submit"
          onClick={() => {
            if (!duplicatingToLocation) {
              return
            }
            duplicateJob({
              jobId: jobId,
              newLocationId: duplicatingToLocation.id,
            })
          }}
        >
          {isDuplicatingJob && (
            <ButtonSpinner className="-ml-0.5 mr-2 h-4 w-4 animate-spin " />
          )}
          {t('duplicateTo')}
        </Button>
      </div>
    </HeaderModal>
  )
}
