import { Pagination as MantinePagination } from '@mantine/core'
import { FC } from 'react'

import { classNames } from '@people/ui/utils'

import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  initialPage: number
  activePage: number
  onSetPage: (page: number) => void
  className?: string
  totalPages: number
}

export const Pagination: FC<PaginationProps> = ({
  initialPage,
  activePage,
  onSetPage,
  className,
  totalPages,
}) => {
  return (
    <MantinePagination
      className={classNames('', className || '')}
      position="center"
      initialPage={initialPage}
      page={activePage}
      spacing={0}
      onChange={onSetPage}
      boundaries={2}
      itemComponent={({ active, onClick, page }) => {
        return (
          <PaginationItem
            active={active}
            onClick={onClick}
            page={page}
            totalPages={totalPages}
            selectedPage={activePage}
          />
        )
      }}
      total={totalPages}
    />
  )
}
