import { SortingState } from '@tanstack/react-table'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

export const useUrlSorting = ({
  defaultSorting,
}: {
  defaultSorting?: SortingState
}) => {
  const { query, replace } = useRouter()

  const urlSorting = useMemo(() => {
    const urlSorting = query.sorting as string
    try {
      return urlSorting.split(',').map(s => {
        const [field, order] = s.split('-')

        if (!field) {
          throw new Error('Missing field')
        }

        if (!order) {
          return {
            id: field,
            desc: true,
          }
        }

        return {
          id: field,
          desc: order === 'desc',
        }
      })
    } catch (e) {
      if (query.sorting) {
        console.log('Invalid sorting for url', query.sorting?.toString())
      }
      return defaultSorting || []
    }
  }, [query.sorting])

  const [sorting, setSorting] = useState<SortingState>(urlSorting)

  useEffect(() => {
    const paramSorting = sorting
      .map(s => {
        return `${s.id}-${s.desc ? 'desc' : 'asc'}`
      })
      .join(',')

    if (query.sorting !== paramSorting) {
      replace(
        {
          query: {
            ...query,
            sorting: paramSorting,
          },
        },
        undefined,
        { shallow: true },
      )
    }
  }, [sorting, query, replace])

  return { sorting, setSorting }
}
