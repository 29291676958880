import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import type { FC } from 'react'

import { DropdownItem } from '@people/ui/dropdown'
import { EmbedIcon } from '@people/ui/icons'

import {  ShareIcon} from '@heroicons/react/outline'
import { useIframeModal } from './IframeModal'

export interface EmbedButtonProps {
  type: 'job' | 'location' | 'organisation' | 'jobShare'
  url: string
  positionSlug?: string | null
  isTalentPool?: boolean
}

export const EmbedButton: FC<EmbedButtonProps> = ({
  type,
  url,
  positionSlug,
  isTalentPool,
}) => {
  const { iFrameModalOpen } = useIframeModal()
  const { push, query } = useRouter()
  const { t } = useTranslation('common')
  return (
    <DropdownItem
      className="flex w-full !justify-start"
      onClick={e => {
        e.stopPropagation()
        switch (type) {
          case 'job':
            iFrameModalOpen({
              jobFullSlug: url,
              positionSlug,
              isTalentPool
            })
            break
          case 'jobShare':
            iFrameModalOpen({
              shareJobFullSlug: url,
              positionSlug,
              isTalentPool
            })
            break
          case 'location':
            iFrameModalOpen({
              locationFullSlug: url,
            })
            break
          case 'organisation':
            push(`/${query.orgSlug}/settings/embed`)
            break
        }
      }}
    >
      {type === 'jobShare' ? (
        <ShareIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
      ) : (
        <EmbedIcon className="mr-3 h-[18px] w-[18px] text-blue-600" />
      )}
      {(() => {
        switch (type) {
          case 'job':
            return t('embedJobForm')
          case 'jobShare':
            return t('shareJobForm')
          case 'location':
            return t('embedLocation')
          case 'organisation':
            return t('embedCareer')
        }
      })()}
    </DropdownItem>
  )
}

export default EmbedButton
