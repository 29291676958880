import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/outline'
import { PaginationItemProps as MantinePaginationItemProps } from '@mantine/core/lib/Pagination/DefaultItem/DefaultItem'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { classNames } from '@people/ui/utils'

interface PaginationItemProps extends MantinePaginationItemProps {
  totalPages: number
  selectedPage: number
}

type PageType = number | 'next' | 'prev' | 'dots'

export const PaginationItem: FC<PaginationItemProps> = ({
  active,
  page,
  onClick,
  totalPages,
  selectedPage,
}) => {
  const { t } = useTranslation('common')

  const displayPage = ({ page }: { page: PageType }) => {
    if (typeof page === 'number') {
      return page
    }

    if (page === 'prev') {
      return (
        <div className="flex flex-row items-center mx-2.5 gap-1.5">
          <ArrowCircleLeftIcon
            className={classNames(
              'w-5',
              selectedPage > 1 ? 'text-blue-600' : 'text-slate-500',
            )}
          />
          {t('previous')}
        </div>
      )
    } else if (page === 'next') {
      return (
        <div className={classNames('flex flex-row items-center gap-1.5')}>
          {t('next')}
          <ArrowCircleRightIcon
            className={classNames(
              'w-5',
              selectedPage < totalPages ? 'text-blue-600' : 'text-slate-500',
            )}
          />
        </div>
      )
    } else if (page === 'dots') {
      return <DotsHorizontalIcon className="w-5 pt-2.5" />
    }

    return <></>
  }

  const isItemDisabled =
    (page === 'prev' && selectedPage === 1) ||
    (page === 'next' && selectedPage === totalPages)

  return (
    <div
      onClick={() => {
        if (!isItemDisabled) {
          onClick?.()
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
      }}
      className={classNames(
        'flex items-center justify-center font-medium cursor-pointer w-10 h-10  border-l border-t border-b border-slate-300  hover:text-blue-600',
        active ? 'bg-blue-50 text-blue-600' : '',
        page === 'prev' ? 'rounded-l-md w-[110px]' : '',
        page === 'next' ? 'border-r rounded-r-md w-[80px]' : '',
        isItemDisabled ? 'text-slate-500 hover:text-slate-500' : '',
      )}
    >
      {displayPage({
        page: page as PageType,
      })}
    </div>
  )
}
