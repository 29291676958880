import { trpc } from '@/utils/trpc'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { FC, Fragment, useState } from 'react'

import { Button } from '@people/ui/buttons'
import { Banner } from '@people/ui/notification'

interface DisabledWebhookWarningsProps {
  orgSlug: string
  getBy: 'org' | 'job'
  jobId?: string
}

export const DisabledWebhookWarnings: FC<DisabledWebhookWarningsProps> = ({
  orgSlug,
  getBy,
  jobId,
}) => {
  const { t } = useTranslation('common')

  const [hideWebhookWarning, setHideWebhookWarning] = useState<boolean>(false)

  const { data: webhooks, isLoading: isLoadingDisabledWebhooksWithIssues } =
    trpc.useQuery(
      [
        'org.webhook.getDisabledWebhooksWithIssues',
        {
          orgSlug: orgSlug,
          jobId,
        },
      ],
      {
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled:
          (getBy === 'org' ? Boolean(orgSlug) : Boolean(orgSlug && jobId)) &&
          !hideWebhookWarning,
      },
    )

  return (
    <Fragment>
      {webhooks?.map(w => {
        return (
          <Banner
            key={w.id}
            type={'error'}
            title={`Webhook "${w.name}" is disabled`}
            message={t('webhookEndpointIssue')}
            onClose={() => {
              setHideWebhookWarning(true)
            }}
            CTA={
              <Link href={`/${orgSlug}/settings/export-data`}>
                <Button
                  variant="primary"
                  tabIndex={-1}
                  className="w-[130px] h-8 text-sm"
                >
                  {t('goToWebhooks')}
                </Button>
              </Link>
            }
          />
        )
      })}
    </Fragment>
  )
}
