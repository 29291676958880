import type { SortDirection } from '@tanstack/react-table'
import type { FC } from 'react'
import { classNames } from '../utils/classNames'

export interface TableSorterProps {
  className?: string
  isSorted?: boolean | SortDirection
}

export const TableSorter: FC<TableSorterProps> = ({ className, isSorted }) => {
  return (
    <svg
      className={classNames(className ?? '', 'ml-1 fill-blue-600')}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      {(!isSorted || isSorted === 'asc') && (
        <path d="M6.85498 7.2344L8.9992 5.16919L11.1434 7.2344C11.2305 7.31856 11.334 7.38536 11.4481 7.43094C11.5622 7.47653 11.6846 7.5 11.8082 7.5C11.9318 7.5 12.0542 7.47653 12.1683 7.43094C12.2824 7.38536 12.386 7.31856 12.473 7.2344C12.5608 7.15092 12.6305 7.05161 12.678 6.94219C12.7255 6.83277 12.75 6.71541 12.75 6.59688C12.75 6.47834 12.7255 6.36098 12.678 6.25156C12.6305 6.14214 12.5608 6.04283 12.473 5.95935L9.664 3.2656C9.57696 3.18144 9.4734 3.11464 9.3593 3.06906C9.2452 3.02347 9.12281 3 8.9992 3C8.87559 3 8.75321 3.02347 8.63911 3.06906C8.525 3.11464 8.42144 3.18144 8.3344 3.2656L5.52537 5.95935C5.34905 6.12844 5.25 6.35776 5.25 6.59688C5.25 6.83599 5.34905 7.06532 5.52537 7.2344C5.70169 7.40348 5.94082 7.49847 6.19017 7.49847C6.43952 7.49847 6.67866 7.40348 6.85498 7.2344Z" />
      )}
      {(!isSorted || isSorted === 'desc') && (
        <path d="M8.9992 12.8301L11.1434 10.7642C11.3197 10.595 11.5589 10.5 11.8082 10.5C12.0576 10.5 12.2967 10.595 12.473 10.7642C12.6493 10.9333 12.7484 11.1627 12.7484 11.4019C12.7484 11.6411 12.6493 11.8705 12.473 12.0396L9.664 14.7343C9.57696 14.8185 9.4734 14.8853 9.3593 14.9309C9.2452 14.9765 9.12281 15 8.9992 15C8.87559 15 8.75321 14.9765 8.63911 14.9309C8.525 14.8853 8.42144 14.8185 8.3344 14.7343L5.52537 12.0396C5.43807 11.9559 5.36881 11.8565 5.32157 11.747C5.27432 11.6376 5.25 11.5203 5.25 11.4019C5.25 11.2835 5.27432 11.1662 5.32157 11.0568C5.36881 10.9473 5.43807 10.8479 5.52537 10.7642C5.61267 10.6804 5.71632 10.614 5.83038 10.5687C5.94445 10.5233 6.06671 10.5 6.19017 10.5C6.31364 10.5 6.4359 10.5233 6.54996 10.5687C6.66403 10.614 6.76767 10.6804 6.85498 10.7642L8.9992 12.8301Z" />
      )}
    </svg>
  )
}

export default TableSorter