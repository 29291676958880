import { forwardRef, ReactNode } from 'react'

import { classNames } from '../utils/classNames'

export interface TabProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  current: boolean
  count?: number
  twoLines?: boolean
  labelClassName?: string
  countClassName?: string
  icons?: ReactNode
}

export const Tab = forwardRef<HTMLAnchorElement, TabProps>(
  (
    {
      labelClassName,
      countClassName,
      className,
      current,
      count,
      twoLines,
      children,
      icons,
      ...other
    },
    ref,
  ) => {
    return (
      <a
        className={classNames(
          'border-b border-transparent',
          className ?? '',
          current ? '!border-y-blue-600' : 'hover:border-y-slate-200',
          twoLines
            ? 'flex-col items-start px-[18px] py-3'
            : 'flex-row items-center py-3.5',
          'flex justify-center gap-2',
        )}
        aria-current={current ? 'page' : undefined}
        ref={ref}
        {...other}
      >
        <div
          className={classNames(
            current ? 'text-blue-600' : ' text-slate-600  hover:text-slate-800',
            'flex cursor-pointer items-center text-center font-medium',
            labelClassName || '',
          )}
        >
          {children}
        </div>
        <div className="flex items-center gap-2">
          {count !== undefined && (
            <div
              className={classNames(
                'bg-slate-200 text-slate-600',
                'rounded px-1 py-0.5 text-xs font-medium',
                countClassName || '',
                // bg-blue-100 = !bg-[#DBEAFE], but for some reason it was not working properly without the hex in this case. I.e bg-blue-200 would work, but bg-blue-100 would not
                current ? '!bg-[#DBEAFE] !text-blue-600' : '',
              )}
            >
              {count}
            </div>
          )}
          {icons}
        </div>
      </a>
    )
  },
)

export default Tab
