import { Transition } from '@headlessui/react'
import { useClickOutside } from '@mantine/hooks'
import { FC, Fragment, MouseEvent, ReactNode, useState } from 'react'

import { classNames } from '../utils/classNames'

export const useTableDropdown = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })

  const handleReferenceElementClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { x, y } = e.currentTarget?.getBoundingClientRect()
    setPopoverPosition({ top: y, left: x })
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    setIsOpen,
    popoverPosition,
    handleReferenceElementClick,
  }
}

export interface TableDropdownProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  top: number
  left: number
  items: ReactNode
  className?: string
  itemsClassName?: string
}

export const TableDropdown: FC<TableDropdownProps> = ({
  isOpen,
  setIsOpen,
  top,
  left,
  items,
  className,
}) => {
  const ref = useClickOutside(() => setIsOpen(false))

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      // @ts-ignore (works)
      ref={ref}
    >
      <div
        className={classNames('absolute', className ?? '')}
        style={{ top: top + 40 + window.scrollY, left: left + window.scrollX }}
      >
        {items}
      </div>
    </Transition>
  )
}

export default TableDropdown
